import React from 'react';
import Video from '../components/youtube-iframe';

const SecondPage = ({ location }) => (
  <div>
    <Video title="" autoplay="1" videoId={location.hash.substring(1)}  />
  </div>
);

	export default SecondPage;
